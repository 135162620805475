import React, { useEffect, useState } from 'react';
import './Devices.css';

const Devices = () => {
    const [devices, setDevices] = useState([]);
    const [timestamp, setTimestamp] = useState('');
    const [socket, setSocket] = useState(null);
  
    useEffect(() => {
      const connectWebSocket = () => {
        const newSocket = new WebSocket('wss://adminsocket.vistaai.workers.dev');
  
        newSocket.addEventListener('open', () => {
          console.log('Connection Established');
        });
  
        newSocket.addEventListener('message', (event) => {
          const data = JSON.parse(event.data);
          console.log(data);
  
          if (data.error) {
            console.error(data.error);
            console.log('close send')
            newSocket.close(); // Close the current socket
            return; // Exit the message handler if there's an error
          }
  
          setDevices(data.devices || []);
          setTimestamp(data.timestamp || '');
        });
  
        newSocket.addEventListener('close', () => {
          console.log('Connection Closed, reconnecting...');
          setSocket(null);
          setTimeout(connectWebSocket, 3000); // Reconnect after 3 seconds
        });
  
        setSocket(newSocket);
      };
  
      if (!socket) {
        connectWebSocket();
      }
  
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }, [socket]);

  const formatDate = (isoString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      timeZoneName: 'short' 
    };
    return new Date(isoString).toLocaleString(undefined, options);
  };

  return (
    <div className="container">
      <h1 className="title">Devices Data</h1>
      {devices.length > 0 ? (
        <div className="device-list">
          {devices.map((device, index) => (
            <div className="device-card" key={index}>
              <div className="device-info">
                <strong>MAC Address:</strong> {device.mac_address}<br />
                <strong>Last Activity:</strong> {formatDate(device.last_activity)}<br />
                <strong>Last Connected IP:</strong> {device.last_connected_ip}<br />
                <strong>Status:</strong> 
                <span className={device.is_online ? 'online' : 'offline'}>
                  {device.is_online ? 'Online' : 'Offline'}
                </span>
              </div>
              <span className="timestamp">Last Updated: {formatDate(timestamp)}</span>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-data">No devices available at the moment.</p>
      )}
    </div>
  );
};

export default Devices;
