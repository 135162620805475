import React, { useEffect, useState } from 'react';
import './IPs.css';

const IPs = () => {
  const [ips, setIps] = useState([]);
  const [timestamp, setTimestamp] = useState('');
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const connectWebSocket = () => {
      const newSocket = new WebSocket('wss://adminsocket.vistaai.workers.dev');

      newSocket.addEventListener('open', () => {
        console.log('Connection Established');
      });

      newSocket.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);
        console.log(data);

        if (data.error && data.error.includes("Too many subrequests")) {
          console.error(data.error);
          console.log('close send')
          newSocket.close(); // Close the current socket
          return; // Exit the message handler if there's an error
        }

        setIps(data.ips || []);
        setTimestamp(data.timestamp || '');
      });

      newSocket.addEventListener('close', () => {
        console.log('Connection Closed, reconnecting...');
        setTimeout(connectWebSocket, 3000); // Reconnect after 3 seconds
      });

      setSocket(newSocket);
    };

    if (!socket) {
      connectWebSocket();
    }

    return () => {
      if (socket) {
      }
    };
  }, [socket]);

  const formatDate = (isoString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      timeZoneName: 'short' 
    };
    return new Date(isoString).toLocaleString(undefined, options);
  };

  const getFlagImage = (countryCode) => {
    return `https://flagcdn.com/w320/${countryCode.toLowerCase()}.png`;
  };

  return (
    <div className="container">
      <h1 className="title">IP Data</h1>
      {ips.length > 0 ? (
        <div className="ip-list">
          {ips.map((ip, index) => (
            <div className="ip-card" key={index}>
              <div className="ip-info">
                <strong>IP Address:</strong> {ip.ip_address}<br />
                <strong>Devices Connected:</strong> {ip.device_count}<br />
                <strong>Organization:</strong> {ip.asOrganization}<br />
                <strong>City:</strong> {ip.city}<br />
                <strong>Region:</strong> {ip.region}<br />
                <strong>Country:</strong> {ip.country}
                <img src={getFlagImage(ip.country)} className="flag" alt={`Flag of ${ip.country}`} />
              </div>
              <span className="timestamp">Last Updated: {formatDate(timestamp)}</span>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-data">No IPs available at the moment.</p>
      )}
    </div>
  );
};

export default IPs;
