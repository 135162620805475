import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Devices from './components/Devices';
import IPs from './components/IPs';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <h1 className="main-title">Device and IP Tracker</h1>
        <div className="button-container">
          <Link to="/devices" className="nav-button">Devices</Link>
          <Link to="/ips" className="nav-button">IP Data</Link>
        </div>
        <div className="content-container">
          <Routes>
            <Route path="/devices" element={<Devices />} />
            <Route path="/ips" element={<IPs />} />
            <Route path="/" element={<Devices />} /> {/* Default Route */}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
